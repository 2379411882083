<template>
	<v-dialog v-model="dialog" max-width="500px" persistent>
		<v-card>
			<v-card-title>
				<v-progress-linear
					:active="isLoadingCostCenter"
					:indeterminate="isLoadingCostCenter"
					absolute
					top
				></v-progress-linear>
				<span class="text-body-1 primary--text">Editar Centro de Costo</span>
			</v-card-title>
			<v-card-text>
				<v-container>
					<validation-alert :validationErrors="validationErrors" />
					<v-form ref="form" v-model="isValidFormDialog" lazy-validation>
						<v-row>
							<v-col cols="12" sm="12" md="12">
								<v-select
									v-model="editedItem.company_id"
									:items="companies"
									:rules="companyRules"
									@change="listenCompany"
									item-text="name"
									item-value="id"
									label="Empresa"
								></v-select>
							</v-col>
							<v-col cols="12" sm="12" md="12">
								<v-select
									v-model="editedItem.management_id"
									:items="managements"
									:rules="managementIdRules"
									item-text="name"
									item-value="id"
									label="Gerencia"
								></v-select>
							</v-col>
							<v-col cols="12" sm="12" md="12">
								<v-text-field
									v-model="editedItem.costCenter"
									:rules="costCenterRules"
									label="Centro de Costos"
									required
								></v-text-field>
							</v-col>
							<v-col cols="12" sm="12" md="12">
								<v-text-field
									v-model="editedItem.name"
									:rules="nameRules"
									label="Nombre"
									required
								></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-autocomplete
									v-model="editedItem.pep_id"
									:items="peps"
									item-text="code"
									item-value="id"
									label="PEP"
								></v-autocomplete>
							</v-col>
							<v-col cols="12">
								<v-autocomplete
									v-model="editedItem.responsible_id"
									:items="users"
									item-text="fullName"
									item-value="id"
									label="Responsable"
									@keyup="
										(v) => {
											listenSearch(v.target.value);
										}
									"
									:filter="customFilter"
								></v-autocomplete>
							</v-col>
							<v-col cols="12" sm="12" md="12">
								<v-switch v-model="editedItem.active" label="Estado"></v-switch>
							</v-col>
						</v-row>
					</v-form>
				</v-container>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="grey" text @click="close()"> Cancelar </v-btn>
				<v-btn color="blue darken-1" :loading="isLoading" :disabled="isLoading" text @click="save()">
					Guardar
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import ValidationAlert from '@/components/global/ValidationAlert.vue';
import { $EventBus } from '@/main';
export default {
	name: 'EditCostCenterDialog',

	props: {
		costCenter: Object,
	},

	data: () => ({
		timeout: null,
		previousSearchValue: '',
		dialog: false,
		isValidFormDialog: false,
		nameRules: [(v) => !!v || 'Campo requerido'],
		costCenterRules: [(v) => !!v || 'Campo requerido'],
		companyRules: [(v) => !!v || 'Campo requerido'],
		managementIdRules: [(v) => !!v || 'Campo requerido'],
		pepRules: [(v) => /^[-+]?[0-9]*\.?[0-9]*$/.test(v) || 'Campo numérico'],
		isLoading: false,
		isLoadingCostCenter: false,
		editedItem: {
			id: '',
			name: '',
			pep_id: '',
			active: true,
			costCenter: '',
			company_id: '',
			management_id: '',
			responsible_id: '',
		},
		defaultItem: {
			id: '',
			name: '',
			pep_id: '',
			active: true,
			costCenter: '',
			company_id: '',
			management_id: '',
			responsible_id: '',
		},
	}),

	computed: {
		companies() {
			return this.$store.getters['companies/getCompaniesList'];
		},
		managements() {
			return this.$store.getters['managements/getManagementsList'];
		},
		peps() {
			let listPeps = this.$store.getters['peps/getPepsList'];
			if (this.costCenter.pep_id && this.costCenter.pep_code) {
				listPeps.push({
					id: this.costCenter.pep_id,
					code: this.costCenter.pep_code,
				});
			}
			return listPeps;
		},
		validationErrors() {
			return this.$store.getters['costCenters/getValidationErrors'];
		},
		users() {
			return this.$store.getters['users/getUsers'];
		},
	},

	watch: {
		costCenter(val) {
			if (val && val.id) {
				this.dialog = true;
				this.getCostCenter(val?.id);
				this.$store.dispatch('peps/getPepsList');
				this.$store.dispatch('companies/getCompaniesList');
				this.$store.dispatch('managements/getManagementsList', {
					company_id: this.editedItem.company_id,
				});
				this.$store.dispatch('users/getUsers');
			}
		},
		dialog(val) {
			val || this.close();
		},
	},

	methods: {
		listenSearch(value) {
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				const searchValue = value || '';
				const trimmedSearchValue = searchValue.trim();

				if (searchValue === '') {
					this.$store.dispatch('users/searchGetUsers', {
						search: '',
						active: true,
						searchType: 'select',
						pagination: false,
					});

					this.previousSearchValue = '';
					return;
				}

				if (trimmedSearchValue === '') {
					return;
				}

				if (this.previousSearchValue !== trimmedSearchValue) {
					this.$store.dispatch('users/searchGetUsers', {
						search: trimmedSearchValue,
						active: true,
						searchType: 'select',
						pagination: false,
					});

					this.previousSearchValue = trimmedSearchValue;
				}
			}, 600);
		},

		customFilter(item, queryText, itemText) {
			const searchTerms = queryText.toLowerCase().split(' ');
			const fullName = itemText.toLowerCase();

			return searchTerms.every((term) => fullName.includes(term));
		},

		async getCostCenter(costCenter_id) {
			try {
				this.isLoadingCostCenter = true;
				await this.$store.dispatch('costCenters/getCostCenter', costCenter_id);
				this.editedItem = await this.$store.getters['costCenters/getCostCenter'];
			} finally {
				this.isLoadingCostCenter = false;
			}
		},
		close() {
			this.dialog = false;
			this.$nextTick(() => {
				this.editedItem = { ...this.defaultItem };
				this.$refs.form.resetValidation();
			});
		},

		showSnack(color = '', text = '') {
			$EventBus.$emit('showSnack', { color, text });
		},

		listenCompany() {
			this.editedItem.management_id = null;
			this.$store.dispatch('managements/getManagementsList', {
				company_id: this.editedItem.company_id,
			});
		},

		async save() {
			if (this.$refs.form.validate()) {
				this.isLoading = true;

				let data = {
					id: this.editedItem.id,
					name: this.editedItem.name.trim(),
					pep_id: this.editedItem.pep_id,
					active: this.editedItem.active,
					management_id: this.editedItem.management_id,
					costCenter: this.editedItem.costCenter.trim(),
					responsible_id: this.editedItem.responsible_id,
				};
				try {
					await this.$store.dispatch('costCenters/updateCostCenter', data);
					this.showSnack('green', 'Centro de Costo actualizada');
					this.$emit('resetFilter');
					this.close();
				} catch (error) {}
				this.isLoading = false;
			}
		},
	},
	components: {
		ValidationAlert,
	},
};
</script>
