<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        class="my-1 ml-2 text-none"
        v-bind="attrs"
        v-on="on"
        rounded
      >
        <v-icon left> mdi-plus </v-icon>
        Añadir PEP a CECO
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-body-1 primary--text"
          >Añadir PEP a centro de costos</span
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <validation-alert :validationErrors="validationErrors" />
          <v-form ref="form" v-model="isValidFormDialog" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="editedItem.costCenter_id"
                  :items="costCenters"
                  :rules="costCenterIdRules"
                  item-text="costCenter"
                  item-value="id"
                  label="Centro de Costos"
                  no-data-text="..."
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.name"
                  :rules="nameRules"
                  label="Nombre"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="editedItem.pep_id"
                  :items="peps"
                  :rules="pepRules"
                  item-text="code"
                  item-value="id"
                  label="PEP"
                  no-data-text="No hay PEP disponible"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="close()"> Cancelar </v-btn>
        <v-btn color="blue darken-1" text @click="save()"> Guardar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ValidationAlert from "@/components/global/ValidationAlert.vue";
import { $EventBus } from "@/main";
export default {
  name: "CreatePepDialog",

  data: () => ({
    dialog: false,
    isValidFormDialog: false,
    nameRules: [(v) => !!v || "Campo requerido"],
    pepRules: [(v) => !!v || "Campo requerido"],
    costCenterIdRules: [(v) => !!v || "Campo requerido"],
    editedItem: {
      name: "",
      costCenter_id: "",
      active: true,
      pep_id: null,
    },
    defaultItem: {
      name: "",
      costCenter_id: "",
      active: true,
      pep_id: null,
    },
  }),

  computed: {
    costCenters() {
      return this.$store.getters["costCenters/getCostCentersList"];
    },
    peps() {
      return this.$store.getters["peps/getPepsList"];
    },
    validationErrors() {
      return this.$store.getters["costCenters/getValidationErrors"];
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
      if (val) {
        this.$store.dispatch("costCenters/getAvailableCostCenters");
        this.$store.dispatch("peps/getPepsList");
      }
    },
  },

  created() {},

  methods: {
    showSnack(color = "", text = "") {
      $EventBus.$emit("showSnack", { color, text });
    },

    async save() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        const found = this.costCenters.find(
          (element) => element.id == this.editedItem.costCenter_id
        );
        let data = {
          name: this.editedItem.name,
          costCenter: found?.costCenter,
          pep_id: this.editedItem.pep_id,
          management_id: found.management_id?.id,
          responsible_id: found.responsible_id?.id
        };
        try {
          await this.$store.dispatch("costCenters/savePepInCostCenter", data);
          this.showSnack("green", "Se ha registrado satisfactoriamente");
          this.$emit("resetFilter");
          this.close();
        } catch (error) {}
        this.isLoading = false;
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.$store.dispatch("costCenters/cleanValidationErrors");
        this.$refs.form.resetValidation();
      });
    },
  },
  components: {
    ValidationAlert,
  },
};
</script>
