<template>
  <v-dialog v-model="dialogDelete" max-width="500px">
    <v-card>
      <v-card-title class="text-body-1 primary--text justify-center">
        ¿Estás seguro de que quieres eliminar?
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="deleteItemConfirm()">Si</v-btn>
        <v-btn color="blue darken-1" text @click="closeDelete()">No</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { $EventBus } from "@/main";
export default {
  name: "DeleteCostCenterDialog",
  props: {
    costCenter: Object,
  },
  data: () => ({
    dialogDelete: false,
  }),
  watch: {
    costCenter(val) {
      if (val && val.id) {
        this.dialogDelete = true;
      }
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    showSnack(color = "green", text = "") {
      $EventBus.$emit("showSnack", { color, text });
    },

    async deleteItemConfirm() {
      try {
        await this.$store.dispatch(
          "costCenters/deleteCostCenter",
          this.costCenter.id
        );
        this.showSnack("green", "Centro de Costo eliminado");
        this.$emit("resetFilter");
      } catch (error) {
        this.showSnack("red", error);
      }
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {});
    },
  },
};
</script>
